import React, { useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom'
import DotLoader from "react-spinners/BeatLoader";

import { TextInput, DatePicker, Dropdown, FileUpload, TextArea, RenderIf } from '../../../../components';
import useAxios from '../../../../hooks/useAxios';
import Constants from '../../../../Constants';

const EditCompaniesForm = ({ selectedUser, isSubmittingForm }) => {
  let navigate = useNavigate();
  const [islaoder, setIsLaoder] = useState(null);
  const { postData } = useAxios()

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profilepic: ""
  })
  const [profilefileName, setprofilefileName] = useState({
    fileName: "",
    imageUrl: null
  });


  const validationSchema = Yup.object().shape({
    companyLogo: Yup.mixed(),
    companyname: Yup.string().required('Company Name is required'),
    companyPhonenumber: Yup.string()
      .required('company Phonenumber is required')
      .matches(/^\d{10}$/, "company Phonenumber must be numeric and exactly 10 digits long"),
    // companyemail: Yup.string().required('Company Email is required'),
    companyAddress: Yup.string().required('Company Address is required'),
    firstName: Yup.string().required('FirstName is required'),
    contactPhonenumber: Yup.string()
      .required('Contact Phonenumber is required')
      .matches(/^\d{10}$/, "Contact Phonenumber must be numeric and exactly 10 digits long"),
    lastName: Yup.string().required('lastName is required'),
    emailAddress: Yup.string().required('Email Address is required'),
    domainName: Yup.string().required('Domain Name is required'),
    // databaseName: Yup.string().required('Database Name is required'),
    // databaseIp: Yup.string().required('Database Ip is required'),
    // taxId: Yup.string().required('TaxId is required'),
    // databasePassword: Yup.string().required('databasePassword is required').min(8, 'databasePassword must be at least 8 characters long'),
    // databaseUser: Yup.string().required('Database User Password is required')
    usersLimit: Yup.number()
      .required('Users limit is required')
      .typeError('Users limit must be a number'),
    monthlyPrice: Yup.number()
      .required('Monthly price is required')
      .typeError('Monthly price must be a number'),
    demandsPerMonth: Yup.number()
      .required('Demands per month is required')
      .typeError('Demands per month must be a number'),
    costPerAdditionalUser: Yup.number()
      .required('Cost per additional user is required')
      .typeError('Cost per additional user must be a number'),
    costPerAdditionalDemand: Yup.number()
      .required('Cost per additional demand is required')
      .typeError('Cost per additional demand must be a number'),
    rollOverCredits: Yup.number()
      .required('Roll over credits is required')
      .typeError('Roll over credits must be a number'),
    remainingDemand: Yup.number()
      .required('Remaining demand is required')
      .typeError('Remaining demand must be a number')
  });
  const onSubmit = (values, setErrors) => {
    setIsLaoder(true)

    const subscription = {
      monthlyPrice: values?.monthlyPrice,
      usersLimit: values?.usersLimit,
      demandsPerMonth: values?.demandsPerMonth,
      costPerAdditionalDemand: values?.costPerAdditionalDemand,
      costPerAdditionalUser: values?.costPerAdditionalUser,
      remainingDemand: values?.remainingDemand,
      rollOverCredits: values?.rollOverCredits
    }

    let formData = new FormData();
    formData.append('firstName', values?.firstName)
    formData.append('lastName', values?.lastName)
    formData.append('companyName', values?.companyname)
    formData.append('companyPhoneNumber', values?.companyPhonenumber)
    formData.append('companyAddress', values?.companyAddress)
    formData.append('contactEmail', values?.emailAddress)
    formData.append('contactNumber', values?.contactPhonenumber)
    formData.append('companyLogo', values?.companyLogo ? values?.companyLogo : selectedUser?.companyLogo)
    formData.append('companyId', selectedUser?._id)
    formData.append('accountantName', values?.accountantname)
    formData.append('accountantEmail', values?.accountantemail)
    formData.append('subscription', JSON.stringify(subscription))

    postData(Constants.ApiUrl.companies.updatecompanies, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      setIsLaoder(false)
      isSubmittingForm()
      // LoggedInUserService.UpdateLoggedInUserSession(res?.data?.token);
      console.log(res, "res in the add companies");
    }).catch(err => {

    })

  }
  const onProfileUpload = (files, setFieldValue) => {
    setprofilefileName(files.file.name);
    setprofilefileName((prev) => ({ ...prev, fileName: files.file.name, imageUrl: URL.createObjectURL(files?.file) }))
    setFieldValue('companyLogo', files?.file)
  }
  const onback = (files, setFieldValue) => {
    isSubmittingForm()
  }

  return (
    <>

      <Formik
        initialValues={{
          companyname: selectedUser?.companyName || '',
          companyPhonenumber: selectedUser?.companyPhoneNumber || '',
          // companyemail: selectedUser?.companyEmail || '',
          companyAddress: selectedUser?.companyAddress || '',
          firstName: selectedUser?.firstName || '',
          contactPhonenumber: selectedUser?.contactNumber || '',
          lastName: selectedUser?.lastName || '',
          emailAddress: selectedUser?.contactEmail || '',
          domainName: selectedUser?.domainName || '',
          databaseIp: selectedUser?.databaseIp || '',
          taxId: selectedUser?.taxId || '',
          databaseUser: selectedUser?.databaseUser || '',
          companyLogo: selectedUser?.companyLogo || "",
          ContractDetails: selectedUser?.ContractDetails || "",
          accountantname: selectedUser?.accountantname || "",
          accountantemail: selectedUser?.accountantemail || "",
          usersLimit: selectedUser?.subscription?.usersLimit || 0,
          monthlyPrice: selectedUser?.subscription?.monthlyPrice || 0,
          demandsPerMonth: selectedUser?.subscription?.demandsPerMonth || 0,
          remainingDemand: selectedUser?.subscription?.remainingDemand || 0,
          rollOverCredits: selectedUser?.subscription?.rollOverCredits || 0,
          costPerAdditionalDemand: selectedUser?.subscription?.costPerAdditionalDemand || 0,
          costPerAdditionalUser: selectedUser?.subscription?.costPerAdditionalUser || 0,

        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values);
          // Additional logic if needed
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="add-form p-0">


              <div class="title d-flex justify-content-between align-items-center">
                <div>
                  <h2>Company Information</h2>
                  <p>Fill your company Logo and personal details here.</p>
                </div>
                <button class="btn btn-theme" onClick={() => onback()}><i class="fa-solid fa-plus me-2"></i> Back to Companies</button>

              </div>
              <div className="card">
                <h2 className="title">Company Details</h2>
                <div className="row">
                  <div className="card mb-5">
                    <div className="col-md-12">
                      <div className="file-upload bd-card profile">
                        <img src={profilefileName?.imageUrl ? profilefileName?.imageUrl : `${selectedUser?.companyLogo}`} className="img-fluid" alt="user-logo" />
                        <div className="position-relative">
                          <Field name="profilepic" component={FileUpload} onFileSelected={(files) => onProfileUpload(files, setFieldValue)} />
                          <div className="text text-center">
                            <i className="fa-solid fa-upload"></i>
                            <p>Upload file type .pdf, .jpg, .png</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-md-6">
                        <div className="form-group">
                          <Field name="companyname" type="text" removeMargin={true} className="form-control" label="Company Name" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                      <div className="form-group">
                        <Field name="companyemail" type="email" removeMargin={true} className="form-control" label="Email Address" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div> */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <Field name="companyPhonenumber" type="text" removeMargin={true} className="form-control" label="Company Phone Number" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <Field name="companyAddress" type="text" removeMargin={true} className="form-control" label="Company Address" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                    </div>
                    <h2>Contact Person details</h2>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="firstName" type="text" removeMargin={true} className="form-control" label="First Name" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="lastName" type="text" removeMargin={true} className="form-control" label="Last Name" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="contactPhonenumber" type="text" removeMargin={true} className="form-control" label="Contact Phone Number" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="emailAddress" type="email" removeMargin={true} className="form-control" label="Email Address" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                    </div>

                    <h2>Company Domain Details</h2>
                    <div className='row'>
                      <div className='col-md-6 '>
                        <div className="form-group">
                          <Field name="domainName" type="text" removeMargin={true} className="form-control" label="Domain Name" placeholder="Enter here" component={TextInput} disabled={true} />
                        </div>

                      </div>
                      <div className='col-md-6'>
                      </div>
                    </div>
                    <h2>Finance Company Details</h2>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="accountantname" type="text" removeMargin={true} className="form-control" label="Accountant Name" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="accountantemail" type="text" removeMargin={true} className="form-control" label="Accountant Email" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>

                    </div>

                    <h2>Subscription Details</h2>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className="form-group">
                          <Field name="monthlyPrice" type="text" removeMargin={true} className="form-control" label="Monthly Total Subscription Price" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className="form-group">
                          <Field name="demandsPerMonth" type="text" removeMargin={true} className="form-control" label="Demand Per Month" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className="form-group">
                          <Field name="usersLimit" type="text" removeMargin={true} className="form-control" label="User Limit" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="costPerAdditionalUser" type="text" removeMargin={true} className="form-control" label="Cost per additional user in $" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="costPerAdditionalDemand" type="text" removeMargin={true} className="form-control" label="Cost per additional demand in $" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                    </div>

                    <h2>Current Month Usage</h2>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="remainingDemand" type="text" removeMargin={true} className="form-control" label="Remaning Demands of the month" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <Field name="rollOverCredits" type="text" removeMargin={true} className="form-control" label="Rollover credits" placeholder="Enter here" component={TextInput} />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="btns text-end">
                <span onClick={() => onback()} className="btn btn-theme btn-outline me-3">Cancel</span>
                <button type='submit' className="btn btn-theme">Update</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <DotLoader
        color={'#00a6ff'}
        loading={islaoder}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>

  );
};
export default EditCompaniesForm;


import * as type from '../type';

const initialState = {
    refData: {},
}

export default function FormikRefCapture(state = initialState, action) {
    switch (action.type) {
        case type.REF_DATA_CAPTURED:
            return {
                ...state,
                refData: action.payload
            }
        default:
            return state
    }
}


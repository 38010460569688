import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RenderIf } from "../../components";
import XLoader from "../../components/common/XLoader";
import XSelect from "../../components/common/XSelect";
import Constants from "../../Constants";
import { CASE_TYPE, CASE_TYPE_MAP, DEMAND, DEMAND_GROUP, DEMAND_TYPE, CASE_TYPE_DEMANDS } from '../../utils/enum';
import '../style.css';
import { useTemplateContext } from './TemplateContext';
import TemplateEditableField from './TemplateEditableField';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

const TemplateForm = () => {
    const { templateData, state, onChangeState, sanitizeHtml, getTemplateIsLoading, demandType, caseType, onChangeCaseType, onChangeDemandType, templateBuilderType, onTemplateFileUpload, removeTemplateFile, viewFileInNewTab, templateBuilderTypeToggler, uploadTemplateFileIsLoading, onChangeSettlementTable } = useTemplateContext()

    return (
        <>
            {getTemplateIsLoading && <XLoader />}

            {!getTemplateIsLoading &&

                <div>
                    <div className='row'>
                        <div className="col-md-6">
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch checked={templateBuilderType} onChange={templateBuilderTypeToggler} name="templateBuilderType" />}
                                    labelPlacement="start"
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '18px',
                                        }
                                    }}
                                    label="Template Builder Switcher" />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mt-5">
                            <XSelect
                                emptyOption={false}
                                label="Demand Type"
                                value={demandType}
                                options={CASE_TYPE_DEMANDS?.[caseType]?.map((v, index) => {
                                    return DEMAND_TYPE?.[v]
                                })}
                                onChange={onChangeDemandType}
                            />
                        </div>
                        <div className="col-md-4 mt-5">
                            <XSelect
                                emptyOption={false}
                                label="Case Type"
                                value={caseType}
                                options={Object.values(CASE_TYPE_MAP)}
                                onChange={onChangeCaseType}
                            />
                        </div>
                        <div className="col-md-4 mt-5">
                            <XSelect
                                emptyOption={false}
                                label="State"
                                value={state}
                                onChange={onChangeState}
                                options={Constants.Dropdowns.States}
                            />
                        </div>
                    </div>

                    {!templateBuilderType && <div className="row">
                        <div className="col-md-6">
                            {[DEMAND.TP_PLD, DEMAND.TP_N_PLD, DEMAND.UIM_PLD, DEMAND.UIM_N_PLD, DEMAND.UM_PLD, DEMAND.UM_N_PLD].includes(demandType) &&
                                <div className="col-md-12 mt-5">
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Switch checked={templateData.shouldShowSettlementTable} onChange={onChangeSettlementTable} name="settlementTable" />}
                                            labelPlacement="start"
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '18px',
                                                }
                                            }}
                                            label="Display Settlement Demand Table" />
                                    </FormGroup>
                                </div>
                            }


                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="introductionTitle"
                                    label="Introduction Title"
                                    placeholder="Enter Introduction title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="introductionDescription"
                                    label="Introduction Description"
                                    placeholder="Enter Introduction Description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            {([CASE_TYPE.TRIP_AND_FALL, CASE_TYPE.SLIP_AND_FALL, CASE_TYPE.PREMISE_LIABILITY, CASE_TYPE.DOG_BITE, CASE_TYPE.PRODUCT_LIABILITY].includes(caseType)) &&
                                <>
                                    <div className="col-md-12 mt-5">
                                        <TemplateEditableField
                                            fieldName="factIncidentTitle"
                                            label="Fact Of The Incident"
                                            placeholder="Fact Of The Incident title"
                                        />
                                    </div>
                                    <div className="col-md-12 mt-5">
                                        <TemplateEditableField
                                            fieldName="factIncidentDescription"
                                            label="Fact Of The Incident Description"
                                            placeholder="Fact Of The Incident Description"
                                            isReferenceVariableAvailable={true}
                                        />

                                    </div>
                                </>
                            }




                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="liabilityTitle"
                                    label="Liability Title"
                                    placeholder="Enter liability title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>


                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="liabilityDescription"
                                    label="Liability Description"
                                    placeholder="Enter liability Description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            {(DEMAND_GROUP.REGULAR.includes(demandType)) && <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="priorMedicalRecordTitle"
                                    label="Prior Medical Record Title"
                                    placeholder="Enter Prior Medical Record title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>}

                            {(DEMAND_GROUP.REGULAR.includes(demandType)) && <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="priorMedicalRecordDescription"
                                    label="Prior Medical Record Description"
                                    placeholder="Enter Prior Medical Record description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>}

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="nonEconomicDamageTitle"
                                    label="Non-Economic Damages Title"
                                    placeholder="Enter Non-Economic Damages title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>


                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="nonEconomicDamageDescription"
                                    label="Non-Economic Damage Description"
                                    placeholder="Enter Non-Economic damages description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            {(DEMAND_GROUP.REGULAR.includes(demandType)) && <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="lossOfIncomeTitle"
                                    label="Loss of Income Title"
                                    placeholder="Enter loss of Income"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>}


                            {(DEMAND_GROUP.REGULAR.includes(demandType)) && <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="lossOfIncomeDescription"
                                    label="Loss of Income Description"
                                    placeholder="Enter Loss of Income description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>}

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="settlementDemandTitle"
                                    label="Settlement Demand Title"
                                    placeholder="Enter Settlement demand title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="settlementDemandDescription"
                                    label="Settlement Demand Description"
                                    placeholder="Enter settlement demand description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            {(demandType === DEMAND.TP_PLD) && <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="badFaithExposerTitle"
                                    label="Bad Faith Exposure Title "
                                    placeholder="Enter Bad Faith Exposure Title "
                                    isReferenceVariableAvailable={true}
                                />
                            </div>}

                            {(demandType === DEMAND.TP_PLD) && <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="badFaithExposerDescription"
                                    label="Bad Faith Exposure Description"
                                    placeholder="Enter Bad Faith Exposure Description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>}

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="termOfSettlementTitle"
                                    label="Term of Settlement Title"
                                    placeholder="Enter term of settlement title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="termOfSettlementDescription"
                                    label="Term of Settlement Description"
                                    placeholder="Enter term of settlement description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h2>Preview</h2>
                                <div className="editing-Field" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <ReactQuill
                                                value={sanitizeHtml(templateData?.attorneyName)}
                                                modules={{ toolbar: false }}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <RenderIf shouldRender={templateData?.companyLogo}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img alt="companyLogo" src={templateData?.companyLogo} width="300px" height="200px" style={{ display: 'center' }} />
                                                </div>
                                            </RenderIf>
                                        </div>
                                        <div className="col-md-4">
                                            <ReactQuill
                                                value={sanitizeHtml(templateData?.attorneyEmail)}
                                                modules={{ toolbar: false }}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </div>
                                    </div>
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.firmAddress)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.introductionTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.introductionDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.liabilityTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.liabilityDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.priorMedicalRecordTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.priorMedicalRecordDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.nonEconomicDamageTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.nonEconomicDamageDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.lossOfIncomeTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.lossOfIncomeDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.settlementDemandTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.settlementDemandDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.termOfSettlementTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.termOfSettlementDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.FirmName)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}

                    {(templateBuilderType && !uploadTemplateFileIsLoading) && <div className='row'>
                        <div className="file-upload mb-5">
                            <div className="text text-center">
                                {templateData?.templateFile ? <>
                                    <i className='fa-solid fa-eye'
                                        onClick={() => {
                                            viewFileInNewTab(templateData?.templateFile)
                                        }}
                                    ></i>
                                    <i className='fa-solid fa-trash ms-2'
                                        onClick={removeTemplateFile}
                                    ></i>
                                </> : <>
                                    <input type="file" onChange={onTemplateFileUpload} />
                                    <i className="fa-solid fa-upload"></i>
                                    <p>Upload file type, .docx</p>
                                </>}

                            </div>
                        </div>
                    </div>}
                    {uploadTemplateFileIsLoading && <XLoader />}

                </div>
            }
        </>
    )
}

export default TemplateForm;
import React, { useRef, useState, useEffect } from 'react';
import { Field, Formik, Form } from 'formik';
import { TextInput, Dropdown } from '../../../../components';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Constants from '../../../../Constants';
import * as Yup from 'yup'
import { storeDamageAnalysisEstern, formikRefCapture, cancelCaseSubmission, isCaseSaved } from '../../../../redux/actions/liabilty';

const DamagesAnalysisForm = ({ onSubmit, stepDecrement, onMedicalBillUpload, onIncomeDocmentUpload, onInjuriesPhotosUpload, innerRef }) => {
    const dispatch = useDispatch();
    const DamageData = useSelector(state => state.damageData.damage);
    const painPoints = useRef(["Yardwork", "Laundry", "Dishes", "Grocery Shopping", "Cleaning", "Taking Out Trash", "Car Maintenance", "Gardening", "Raking Leaves/Shoveling Snow", "Relationship with Spouse", "Relationship with Children", "Relationship with Grandchildren",
        "Holding Children", "Family Outings", "Family Play Time", "Family Vacations", "Youth Sports", "Self Grooming", "Self Dressing", "Putting on Shoes", "Self Bathing", "Sleep", "Going To The Gym", "Running", "Walking", "Sex Life"]);
    const [selectedPainPoints, setSelectedPainPoints] = useState([]);
    const [bulletText, setBulletText] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const textareaRef = useRef(null);


    useEffect(() => {
        dispatch(formikRefCapture(innerRef))
        console.log('Damages', innerRef)
    }, [])

    useEffect(() => {
        if (DamageData && Object.keys(DamageData).length > 0) {
            if (DamageData?.painPointsBrief) {
                setBulletText(DamageData?.painPointsBrief)
            }
            if (DamageData?.selectedPainPoints) {
                setSelectedPainPoints(DamageData?.selectedPainPoints)
            }
        } else {
            setBulletText("")
            setSelectedPainPoints([])
        }

    }, [DamageData])

    const handleSelectPainPoints = (fileName) => {
        if (selectedPainPoints.includes(fileName)) {
            setSelectedPainPoints(selectedPainPoints.filter(item => item !== fileName));
        } else {
            setSelectedPainPoints([...selectedPainPoints, fileName]);
        }
    }

    const handleAddBullet = (isNewLine = false) => {
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const bullet = '\u2022 ';
        const newText = bulletText.slice(0, start) + (isNewLine ? '\n' : '') + bullet + bulletText.slice(end);
        setBulletText(newText);
        textarea.focus();
    };

    const handleKeyDown = (e) => {
        const bullet = '\u2022 ';
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddBullet(true);
        } else if (bulletText.length === 0) {
            setBulletText(bullet);
        }
    };


    return (
        <Formik
            initialValues={{
                monthlyamount: DamageData?.monthlyamount || '',
                annualamount: DamageData?.annualamount || '',
                gender: DamageData?.gender || '',
                age: DamageData?.age || '',
                WorkHoursMissed: DamageData?.WorkHoursMissed || '',
                hourlyIncomeRate: DamageData?.hourlyIncomeRate || '',
                typeofWork: DamageData?.typeofWork || '',
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
                dispatch(cancelCaseSubmission(true))
                
                if (selectedPainPoints.length > 0) { values.selectedPainPoints = selectedPainPoints; }
                if (bulletText === undefined) { setBulletText("") } else if (bulletText.length > 0) { values.painPointsBrief = bulletText; }
                if (isSubmitting) {
                    values.processCase = false
                    values.isCaseEdited = false
                    dispatch(storeDamageAnalysisEstern(values));
                } else {
                    values.processCase = true
                    values.isCaseEdited = true
                    dispatch(isCaseSaved(true))
                    onSubmit({ damage: values })
                }
                setIsSubmitting(false); // Reset the state after submission
            }}
        >
            {({ submitForm }) => (
                <Form>
                    <div className="add-form p-0">
                        <div className="card">
                            <h2 className="title">Past and Future Pain and Suffering Calculation</h2>
                            <div className="row">
                                <div className='row'>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <Field name="age" placeholder="Write age..." label="Age" type="number" component={TextInput} />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <Field name="gender"
                                                label="Gender"
                                                component={Dropdown}
                                                defaultOption="Select Gender"
                                                options={Constants.Dropdowns.MrMrs}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <Field name="monthlyamount"
                                                label="Past Monthly Non-Economic Amount"
                                                placeholder="Enter amount"
                                                component={TextInput}
                                                shouldUserRenderIcon={<>   <span style={{ position: 'absolute', color: '#18479a', paddingLeft: '18px', paddingTop: '12px' }}>$</span>    </>}
                                                shouldDollarRender={true}
                                                isDollarSignRender={true}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <Field name="annualamount"
                                                label="Future Annual Non-Economic Amount"
                                                placeholder="Enter amount"
                                                component={TextInput}
                                                shouldUserRenderIcon={<>   <span style={{ position: 'absolute', color: '#18479a', paddingLeft: '18px', paddingTop: '12px' }}>$</span>    </>}
                                                shouldDollarRender={true}
                                                isDollarSignRender={true}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="title">Pain and Suffering Analysis
                            </h2>
                            {/* <div className="row">
                            </div> */}
                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <div className="sub-title">
                                        <i className="fa-sharp fa-solid fa-angle-right"></i>
                                        <span> Pain and Suffering Section</span>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className='row'>
                                                <p>Which of he following activities have been negatively impacted by this accident?</p>
                                                {painPoints.current.map((item) => {
                                                    return (
                                                        <div className="col-sm-6 col-md-4  col-xl-3" key={item}>
                                                            <FormControlLabel
                                                                label={item}
                                                                control={<Checkbox
                                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#18479A' } }}
                                                                    checked={selectedPainPoints.includes(item)}
                                                                    onChange={() => handleSelectPainPoints(item)}
                                                                />}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <p>Describe any significant ways that your client's life continues to be limited or affected presently as a result of any ongoing conditions from incident.</p>
                                            <textarea value={bulletText} onKeyDown={handleKeyDown} style={{ whiteSpace: 'pre-wrap', height: "15rem" }} ref={textareaRef} onChange={(e) => { setBulletText(e.target.value) }} placeholder='Write here...' className={`textarea-field-style`} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="sub-title">
                                        <i className="fa-sharp fa-solid fa-angle-right"></i>
                                        <span> Loss of Income Section
                                        </span>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <Field name="WorkHoursMissed" label="Enter Work Hours Missed" component={TextInput} isRenderCommaDecimal={true} />
                                        </div>
                                        <div className="form-group" style={{ marginTop: '3rem' }}>
                                            <Field name="hourlyIncomeRate" type="text" label="Hourly Income Rate" placeholder="Enter here" shouldUserRenderIcon={<>   <span style={{ position: 'absolute', color: '#18479a', paddingLeft: '18px', paddingTop: '12px' }}>$</span>    </>} component={TextInput} shouldDollarRender={true} isDollarSignRender={true} />
                                        </div>
                                        <div className="form-group" style={{ marginTop: '4rem' }}>
                                            <Field name="typeofWork" label="Enter Type of Work" placeholder="Enter here" component={TextInput} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btns text-center">
                            <button className="btn-theme btn-outline me-3"
                                onClick={async () => {
                                    setIsSubmitting(true);
                                    await submitForm();
                                    stepDecrement();
                                }}
                                ref={innerRef}>Previous</button>
                            <button className="btn-theme" type='submit' >Submit</button>
                        </div>
                    </div>
                </Form>
            )
            }
        </Formik >
    )
}

export default DamagesAnalysisForm;
import React, { useState, useEffect } from "react";

import { AddEditUserDialog } from "./components";
import ReactPagination from "../../../components/common/Pagination";
import useSortableData from "../../../components/customHook/SortableTable";
import LoggedInUserService from "../../../services/AuthService";
import { BsPencil } from 'react-icons/bs';
import { RenderIf } from "../../../components";
import EditDialog from "./components/EditDialog";
import '../style.css';
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "../../../utils/swal";
import { userLimitReachedHtml } from "../../../utils/constant";
import { setUser } from "../../../redux/actions/users";
import useAxios from "../../../hooks/useAxios";
import Constants from "../../../Constants";
import { toaster } from "../../../utils/toast";
import XLoader from "../../../components/common/XLoader";
import { debounce } from "../../../utils/helper";

const Users = () => {
    const [isAddUserDialogOpened, setIsAddUserDialogOpened] = useState(false);
    const [isEditUserDialogOpened, setisEditUserDialogOpened] = useState(false);
    const [AddDialog, setAddDialog] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const { items: listUsers, requestSort, sortConfig } = useSortableData(usersList, { direction: "sorting_asc", key: "firstName.lastName" });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [searchText, setSearchText] = useState("")
    const [showEntries, setShowEntries] = useState(10)
    const [selectedUser, setSelectedUser] = useState(null);
    const { isAdmin = false,  subscription={} } = useSelector((state) => state.users || {});
    const remainingUser = subscription?.remainingUser || 0;
    const dispatch = useDispatch();
    const { getData: getUserFetchData, isLoading: getUserIsLoading, postData } = useAxios()

    useEffect(() => {
        getUsers();
    }, [currentPage, searchText, showEntries, isSubmittingForm])

    const getUsers = async () => {
        const url = `${Constants.ApiUrl.user.getUsers}?pageNumber=${currentPage}&limit=${showEntries}&searchText=${searchText}`;
        const { success, data, message } = await getUserFetchData(url);
        if (success) {
            const { users, totalCount, totalPages, subscription } = data;
            setUsersList(users);
            setTotalPages(totalPages);
            setTotalCount(totalCount);
            dispatch(setUser({ subscription }));
        } else {
            toaster({ message, success });
        }
    };

    const onAddEditUserSubmit = (isAdd, formData, setErrors) => {

        setIsSubmittingForm(true);
        if (isAdd) {
            addUser(formData, setErrors);
            return;
        }
    }
    const onEditUserSubmit = (isAdd, formData, setErrors, selectedUser) => {

        setIsSubmittingForm(true);
        if (isAdd) {
            editUser(formData, setErrors, selectedUser);
            return;
        }
    }

    const editUser = (formData, setErrors, selectedUser) => {
        
        const data = LoggedInUserService.GetLoggedInUserData();
        const values = {
            confirmPassword: formData.confirmPassword,
            email: formData.email,
            firstName: formData.firstName,
            isAdmin: formData.isAdmin,
            lastName: formData.lastName,
            mobileNumber: formData.mobileNumber,
            password: formData.password,
            _id: selectedUser?._id,
            domainName: data.domainName,
        }
        console.log(formData, data, values, "formData in edit user");
        postData(Constants.ApiUrl.user.updateUser, values).then(resp => {
            onFormSubmitted();
        }).catch(err => {
            setErrors({ ...err?.response?.data?.message })
            setIsSubmittingForm(false);
            setSelectedUser(false);

        })


    }
    const addUser = (formData, setErrors) => {
        const data = LoggedInUserService.GetLoggedInUserData();
        const values = {
            confirmPassword: formData.confirmPassword,
            email: formData.email,
            firstName: formData.firstName,
            isAdmin: formData.isAdmin,
            lastName: formData.lastName,
            mobileNumber: formData.mobileNumber,
            password: formData.password,
            domainName: data.domainName ? data.domainName : "",
        }
        console.log(formData, data, values, "formData");

        postData(Constants.ApiUrl.user.add, values).then(resp => {
            onFormSubmitted();
        }).catch(err => {
            setErrors({ ...err?.response?.data?.message })
            setIsSubmittingForm(false);
            //  onFormSubmitted();
        })

    }

    const onFormSubmitted = () => {
        setIsSubmittingForm(false);
        setIsAddUserDialogOpened(false);
        setSelectedUser(false);
    }
    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    const handleEdit = (user) => {
        console.log(user, "UpdateUser");
        setisEditUserDialogOpened(true);
        setSelectedUser(user);
        setIsSubmittingForm(false);
    };

    const openAddDialog = async () => {

        if (remainingUser <= 0) {
            const result = await confirm({ title: 'Subscription Limit Reached', html: userLimitReachedHtml });
            if (result.isConfirmed) {
                setIsAddUserDialogOpened(true);
                setAddDialog(true)
            }
        } else {
            setIsAddUserDialogOpened(true);
            setAddDialog(true)
        }
    }

    const handleSearchInputChange = (e) => {
        setSearchText(e.target.value)
    };

    const debouncedHandleSearchChange = debounce(handleSearchInputChange, 500);

    return <div className="listing">

        <div class="title d-flex justify-content-between align-items-center">

            <span>List of all users</span>
            {isAdmin && <button class="btn btn-theme" onClick={() => openAddDialog()}><i class="fa-solid fa-plus me-2"></i> Add User</button>}

        </div>
        <RenderIf shouldRender={AddDialog}>
            <AddEditUserDialog
                isDialogOpened={isAddUserDialogOpened}
                onToggleDialog={setIsAddUserDialogOpened}
                onSubmit={onAddEditUserSubmit}
                isSubmittingForm={isSubmittingForm}
            />
        </RenderIf>

        
        <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
            <div class="dataTables_length" id="myTable_length">
                <label>
                    Show
                    <select value={showEntries} onChange={(e) => setShowEntries(parseInt(e.target.value))} name="myTable_length" aria-controls="myTable" class="">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries
                </label>
            </div>
            <div id="myTable_filter" class="dataTables_filter"><label>Search:<input onChange={debouncedHandleSearchChange} type="search" class="" placeholder="" aria-controls="myTable" /></label></div>

            {getUserIsLoading && <div className='tableLoader'>
                <XLoader />
            </div>}

            {!getUserIsLoading && <> <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('firstName.lastName')} className={`sorting ${getClassNamesFor("firstName.lastName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "370.875px" }}>Name</th>
                        <th onClick={() => requestSort('email')} className={`sorting ${getClassNamesFor("email")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "310.038px" }}>Email Address</th>
                        <th onClick={() => requestSort('mobileNumber')} className={`sorting ${getClassNamesFor("mobileNumber")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "250.475px" }} >Phone Number</th>
                        <th onClick={() => requestSort('firstName')} className={`sorting ${getClassNamesFor("firstName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "219.012px" }}>Added Cases</th>

                        {isAdmin && <th onClick={() => requestSort('firstName')} className={`sorting ${getClassNamesFor("firstName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "219.012px" }}>Edit</th>}

                    </tr>
                </thead>
                <tbody>
                    {
                        listUsers?.map((user, i) => (
                            <tr class="odd">
                                <td class="user-name sorting_1">
                                    {/* <img src="images/user.png" class="img-fluid me-2" /> */}
                                    <span>{user?.firstName} {user?.lastName}</span>
                                </td>
                                <td>{user?.email}</td>
                                <td>{user?.mobileNumber}</td>
                                <td>{user?.casesCount}</td>
                                {isAdmin && <td>
                                    <button className="btn btn-viewPdf1" style={{ width: '5rem', height: '3rem' }} onClick={() => { handleEdit(user) }}>
                                        <BsPencil />
                                    </button>
                                </td>}
                            </tr>
                        ))

                    }
                </tbody>
            </table>
                <div class="dataTables_info" id="myTable_info" role="status" aria-live="polite">Showing 1 to {showEntries} of {totalCount} entries</div>
                {totalPages > 1 && <div class="dataTables_paginate paging_simple_numbers" id="myTable_paginate">
                    <ReactPagination
                        activePage={currentPage}
                        totalItemCount={totalPages}
                        onChange={handlePageChange}
                    />
                </div>}</>}
        </div>

        <RenderIf shouldRender={selectedUser}>
            <EditDialog
                isDialogOpened={isEditUserDialogOpened}
                onToggleDialog={setisEditUserDialogOpened}
                onSubmit={onEditUserSubmit}
                isSubmittingForm={isSubmittingForm}
                selectedUser={selectedUser}
            />
        </RenderIf>
    </div>
}

export default Users;
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import XLoader from "../../components/common/XLoader";
import '../style.css';
import { useTemplateContext } from './TemplateContext';
import TemplateEditableField from "./TemplateEditableField";
import { RenderIf } from '../../components';
import XSelect from '../../components/common/XSelect';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { fontFamilyOptions } from '../../utils/constant';

const CommonTemplateForm = () => {

    const { templateData, getSettingDataIsLoading, onFileSelected, removeCompanyLogo, onChangeAmoutText, onChangeFontFamily, fontFamily } = useTemplateContext()
    return (
        <>
            {getSettingDataIsLoading && <XLoader />}

            {!getSettingDataIsLoading &&

                <div className="row pb-5">
                    <div className="col-md-12">
                        <div className="file-upload mb-5">
                            <div className="text text-center">
                                <input type="file" onChange={onFileSelected} />
                                <RenderIf shouldRender={templateData?.companyLogo}>
                                    <div style={{ margin: "0 auto 50px", width: "450px", position: "relative" }}>
                                        <div style={{ textAlign: "end" }}>
                                            <button type='button' style={{ backgroundColor: "#18479a", color: "#fff" }} onClick={() => removeCompanyLogo()} >
                                                X
                                            </button>
                                        </div>
                                        <img src={templateData?.companyLogo} alt="Company Logo" style={{ height: "auto", width: "450px" }} />
                                    </div>
                                </RenderIf>

                                <RenderIf shouldRender={!templateData?.companyLogo}>
                                    <i className="fa-solid fa-upload"></i>
                                    <p>Upload file type, .jpg, .png</p>
                                </RenderIf>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Switch checked={templateData.billedAmountHeading} onChange={onChangeAmoutText} name="amountHeading" />}
                                        labelPlacement="start"
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '18px', // Adjust the font size here
                                            }
                                        }}
                                        label="Display Howell Amount as Billed Amount" />
                                    {/* .MuiFormControlLabel-label */}
                                </FormGroup>
                            </div>


                            <div className="col-md-6 mb-5">
                                <XSelect
                                    emptyOption={false}
                                    label="Font Family"
                                    value={fontFamily}
                                    onChange={onChangeFontFamily}
                                    options={fontFamilyOptions}
                                    isCommon={true}
                                />
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className="col-md-6">
                                <TemplateEditableField
                                    fieldName="attorneyName"
                                    label="Attorney Name"
                                    placeholder="Enter Attorney Name"
                                    isCommon={true}
                                />
                            </div>

                            <div className="col-md-6 ">
                                <TemplateEditableField
                                    fieldName="attorneyEmail"
                                    label="Attorney Email"
                                    placeholder="Enter Attorney Email"
                                    isCommon={true}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 ">
                                <TemplateEditableField
                                    fieldName="firmName"
                                    label="Firm Name"
                                    placeholder="Enter Firm Name"
                                    isCommon={true}
                                />
                            </div>
                            <div className="col-md-6 ">
                                <TemplateEditableField
                                    fieldName="firmAddress"
                                    label="Firm Address"
                                    placeholder="Enter Firm Address"
                                    isCommon={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CommonTemplateForm
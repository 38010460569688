

import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import '../style.css';
import CommonTemplateData from './CommonTemplateForm';
import './template.css';
import { useTemplateContext } from './TemplateContext';
import TemplateForm from './TemplateForm';


const Template = () => {
    const { fontFamily, templateBuilderType } = useTemplateContext();

    useEffect(() => {
        document.documentElement.style.setProperty('--quill-editor-default-font-family', fontFamily);
    }, [fontFamily]);

    return (
        <>
            <div className="container-fluid">
                
                {!templateBuilderType && <>
                    <h2 className='h1 mb-5'>
                        Global Template Language
                    </h2>
                    <CommonTemplateData />
                </>}
          
                <h2 className='h1 mt-5'>
                    Specific Template Language
                </h2>
                <hr />
                <TemplateForm />
            </div>
        </>
    );
};

export default Template;

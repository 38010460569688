import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from "react-router-dom";
import { TextInput } from '../../components';

import './style.css'
import useAxios from '../../hooks/useAxios';
import Constants from '../../Constants';
import { confirm } from '../../utils/swal';
const ResetPassword = () => {
    const { postData } = useAxios()
    let navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
        confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Passwords must match')
    })
    const onSubmit = (values, setErrors) => {
        const token = Constants.getAuthtoken()?.token;
        values.token = token; 
        postData(Constants.ApiUrl.auth.resetPassword, values).then((res) => {
            if(res.success){
                confirm({ icon: "success", title: res.message }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/profile')
                    }
                });
            }else{
                confirm({ icon: "error", title: res.message });
            }
            
        }).catch(err => {
            confirm({ icon: "error", title: err.message });
        })
    }
    return (
        <>
                    <div className="content">
                        <div className="top">
                            <div className="text">
                                <h1>Change Password?</h1>
                            </div>
                            <Formik
                                initialValues={{
                                    password: '',
                                    confirmPassword: '',
                                }}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setErrors }) => {
                                    onSubmit(values, setErrors)
                                }}
                            >
                                {() => (
                                    <Form>
                                        <div className="fields">
                                            <div className="form-group">
                                                <Field name="password" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                    width: "2.5rem",
                                                    outline: "none",
                                                    height: "48px",
                                                    border: "none",
                                                    position: "absolute"
                                                }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" type="password" placeholder="Enter New Password" component={TextInput} />
                                            </div>
                                            <div className="form-group">
                                                <Field name="confirmPassword" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                    width: "2.5rem",
                                                    outline: "none",
                                                    height: "48px",
                                                    border: "none",
                                                    position: "absolute"
                                                }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" type="password" placeholder="Enter New Password" component={TextInput} />
                                            </div>
                                        </div>
                                        <button type='submit' className="btn-theme btn-login">Submit</button>
                                    </Form>)}
                            </Formik>
                        </div>
                        <Link className="text-theme" to="/profile"><i className="fa-solid fa-arrow-left me-2"></i> Back to profile</Link>
                    </div>
        </>

    )
}

export default ResetPassword;
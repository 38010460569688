import React, { useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { TextInput, DatePicker, Dropdown, FileUpload, TextArea, RenderIf } from '../../../../components';
import Constants from '../../../../Constants';
import DotLoader from "react-spinners/BeatLoader";
import useAxios from '../../../../hooks/useAxios';

const AddCompaniesForm = ({ isSubmittingForm, }) => {
  let navigate = useNavigate();
  const [islaoder, setIsLaoder] = useState(null);
  const { postData } = useAxios()

  const [showPassword, setShowPassword] = useState(null);
  const [user, setUser] = useState({
    profilepic: "",
    emailAddress: "",
    databasePassword: "",
  })
  const [profilefileName, setprofilefileName] = useState({
    fileName: "",
    imageUrl: null
  });

  const validationSchema = Yup.object().shape({
    companyLogo: Yup.mixed(),
    companyName: Yup.string().required('Company Name is required'),
    // companyPhonenumber: Yup.string().required('Company Phonenumber is required').max(10, 'Company Phonenumber must be at least 10 characters long'),
    companyPhonenumber: Yup.string()
      .required('company Phonenumber is required')
      .matches(/^\d{10}$/, "company Phonenumber must be numeric and exactly 10 digits long"),
    // companyemail: Yup.string().required('Company Email is required'),
    companyAddress: Yup.string().required('Company Address is required'),
    firstname: Yup.string().required('Firstname is required'),
    contactPhonenumber: Yup.string()
      .required('Contact Phonenumber is required')
      .matches(/^\d{10}$/, "Contact Phonenumber must be numeric and exactly 10 digits long"),
    lastname: Yup.string().required('Lastname is required'),
    emailAddress: Yup.string().required('Email Address is required'),
    domainName: Yup.string().required('Domain Name is required'),
    // databaseName: Yup.string().required('Database Name is required'),
    // databaseIp: Yup.string().required('Database Ip is required'),
    // taxId: Yup.string().required('TaxId is required'),
    databasePassword: Yup.string().required('databasePassword is required').min(8, 'databasePassword must be at least 8 characters long'),
    accountantemail: Yup.string()
      .email('Invalid email format')
      .required('Accountant Email is required'),
    usersLimit: Yup.number()
      .required('Users limit is required')
      .typeError('Users limit must be a number'),
    monthlyPrice: Yup.number()
      .required('Monthly price is required')
      .typeError('Monthly price must be a number'),
    demandsPerMonth: Yup.number()
      .required('Demands per month is required')
      .typeError('Demands per month must be a number')
    // databaseUser: Yup.string().required('Database User Password is required')
  });
  const onSubmit = (values, setErrors) => {
    console.log(values, "vlaues");
    setIsLaoder(true)
    let formData = new FormData();
    formData.append('firstName', values?.firstname)
    formData.append('lastName', values?.lastname)
    // formData.append('companyEmail', values?.companyemail)
    formData.append('companyName', values?.companyName)
    formData.append('companyPhoneNumber', values?.companyPhonenumber)
    formData.append('companyAddress', values?.companyAddress)
    formData.append('contactEmail', values?.emailAddress)
    formData.append('dataBaseUser', values?.databaseUser)
    formData.append('databaseIP', values?.databaseIp)
    formData.append('taxiationID', values?.taxId)
    formData.append('dataBasePassword', values?.databasePassword)
    formData.append('domainName', values?.domainName + ".demandpro.law")
    formData.append('contactNumber', values?.contactPhonenumber)
    formData.append('companyLogo', values?.companyLogo)
    formData.append('contractDetails', values?.ContractDetails)
    formData.append('accountantname', values?.accountantname)
    formData.append('accountantemail', values?.accountantemail)
    formData.append('monthlyPrice', values?.monthlyPrice)
    formData.append('demandsPerMonth', values?.demandsPerMonth)
    formData.append('usersLimit', values?.usersLimit)

    postData(Constants.ApiUrl.companies.addCompanies, formData, {
      headers: {
         'Content-Type': 'multipart/form-data',
      },
   }).then((res) => {
      setIsLaoder(false)
      isSubmittingForm()
      console.log(res, "res in the add companies");
    }).catch(err => {
      if (err.response.data.message.email) {
        alert(err.response.data.message.email)
      } else if (err.response.data.message.mobileNumber) {
        alert(err.response.data.message.mobileNumber)
      }
    })

  }
  const onProfileUpload = (files, setFieldValue) => {
    setprofilefileName(files.file.name);
    setprofilefileName((prev) => ({ ...prev, fileName: files.file.name, imageUrl: URL.createObjectURL(files?.file) }))
    setFieldValue('companyLogo', files?.file)
  }
  const onback = (files, setFieldValue) => {
    isSubmittingForm()
  }
  const toggleShowPassword = () => setShowPassword(!showPassword);
  return (<>
    <Formik
      initialValues={{
        companyName: '',
        companyPhonenumber: '',
        // companyemail: '',
        companyAddress: '',
        firstname: '',
        contactPhonenumber: '',
        lastname: '',
        emailAddress: '',
        domainName: '',
        databaseIp: '',
        taxId: '',
        databaseUser: '',
        databasePassword: '',
        ContractDetails: "",
        accountantname: "",
        accountantemail: "",
        usersLimit: "",
        monthlyPrice: "",
        demandsPerMonth: "",
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
        // Additional logic if needed
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className="add-form p-0">


            <div class="title d-flex justify-content-between align-items-center">
              <div>
                <h2>Company Information</h2>
                <p>Fill your company Logo and personal details here.</p>
              </div>
              <button class="btn btn-theme" onClick={() => onback()}><i class="fa-solid fa-plus me-2"></i> Back to Companies</button>

            </div>
            <div className="card">
              <h2 className="title">Company Details</h2>
              <div className="row">
                <div className="card mb-5">
                  <div className="col-md-12">
                    <div className="file-upload bd-card profile">
                      {profilefileName?.imageUrl &&
                        <img src={profilefileName?.imageUrl} className="img-fluid" alt="user-logo" />
                      }
                      <div className="position-relative">
                        <Field name="profilepic" component={FileUpload} onFileSelected={(files) => onProfileUpload(files, setFieldValue)} />
                        <div className="text text-center">
                          <i className="fa-solid fa-upload"></i>
                          <p>Upload file type .pdf, .jpg, .png</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field name="companyName" type="text" removeMargin={true} className="form-control" label="Company Name" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <Field name="companyemail" type="email" removeMargin={true} className="form-control" label="Email Address" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field name="companyPhonenumber" type="text" removeMargin={true} className="form-control" label="Company Phone Number" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field name="companyAddress" type="text" removeMargin={true} className="form-control" label="Company Address" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                  </div>
                  <h2>Contact Person details</h2>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="firstname" type="text" removeMargin={true} className="form-control" label="First Name" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="lastname" type="text" removeMargin={true} className="form-control" label="Last Name" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="contactPhonenumber" type="text" removeMargin={true} className="form-control" label="Contact Phone Number" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="emailAddress" type="email" removeMargin={true} className="form-control" label="Email Address" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <button
                          type="button"
                          onClick={toggleShowPassword}
                          className="password-toggle-button" // Add appropriate class for styling
                          style={{
                            position: 'absolute', transform: 'translateY(-50%)', left: "60rem",
                            marginTop: "3.5rem", border: 'none', background: 'transparent'
                          }} // Example inline style for positioning
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                        <Field
                          onClick={() => setShowPassword(true)}
                          name="databasePassword"
                          type={showPassword ? "password" : "text"}
                          component={TextInput}
                          removeMargin={true}
                          className="form-control" label="Password" placeholder="Enter here"
                        />
                      </div>
                    </div>

                  </div>

                  <h2>Company Domain Details</h2>
                  <div className='row'>
                    <div className='col-md-6'>

                      <span style={{ marginLeft: '45rem', marginTop: '12rem', position: 'relative', top: '4rem' }}>.demandpro.law</span>
                      <div className="form-group">
                        <Field name="domainName" type="text" removeMargin={true} className="form-control" label="Domain Name" placeholder="Enter here" component={TextInput} isdomainName={true} />
                      </div>
                      {/* <div className="form-group">
                        <Field name="databaseUser" type="text" removeMargin={true} className="form-control" label="Database User" placeholder="Enter here" component={TextInput} />
                      </div> */}
                      {/* <div className="form-group">
                        <Field name="databaseIp" type="text" removeMargin={true} className="form-control" label="Database IP" placeholder="Enter here" component={TextInput} />
                      </div> */}

                    </div>
                    {/* <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="ContractDetails" type="text" removeMargin={true} className="form-control" label="Monthly Credits" placeholder="Number of demands per month" component={TextInput} isNumeric={true} />
                      </div>
                    </div> */}
                    {/* <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="monthlyPrice" type="text" removeMargin={true} className="form-control" label="Monthly Price" placeholder="Default amount billed each month" value="5000" componenet={TextInput} isNumeric={true} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="noOfUsers" type="text" removeMargin={true} className="form-control" label="Number of Users" placeholder="Number of users to include in company" component={TextInput} isNumeric={true} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="renewalDate" type="text" removeMargin={true} className="form-control" label="Contract Renewal Date" placeholder="Contract Reneweal Date" component={DatePicker} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="costPerAdditionalUser" type="text" removeMargin={true} className="form-control" label="Cost per Additional User" placeholder="" value="50" component={TextInput} isNumeric={true} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="costPerAdditionalCase" type="text" removeMargin={true} className="form-control" label="Cost per Additional Case" placeholder="" value="300" component={TextInput} isNumeric={true} />
                      </div>
                    </div> */}
                  </div>
                  <h2>Finance Company Details</h2>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="accountantname" type="text" removeMargin={true} className="form-control" label="Accountant Name" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group">
                        <Field name="accountantemail" type="text" removeMargin={true} className="form-control" label="Accountant Email" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>

                  </div>
                  <h2>Subscription Details</h2>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className="form-group">
                        <Field name="monthlyPrice" type="text" removeMargin={true} className="form-control" label="Monthly Total Subscription Price" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="form-group">
                        <Field name="demandsPerMonth" type="text" removeMargin={true} className="form-control" label="Demand Per Month" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="form-group">
                        <Field name="usersLimit" type="text" removeMargin={true} className="form-control" label="User Limit" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="btns text-end">
              <a onClick={() => onback()} className="btn btn-theme btn-outline me-3">Cancel</a>
              <button type='submit' className="btn btn-theme">Create</button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
    <DotLoader
      color={'#00a6ff'}
      loading={islaoder}
      size={30}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </>

  );
};
export default AddCompaniesForm;

import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TextInput } from '../../components';
import {  useState } from 'react';
import './style.css'
import useAxios from '../../hooks/useAxios';
import Constants from '../../Constants';
import { confirm } from '../../utils/swal';

const ChangePassword = () => {
    const { postData } = useAxios()
    let [searchParams] = useSearchParams();
    let token = searchParams.get('token');
    let navigate = useNavigate();
    const [isTokenValid, setTokenValid] = useState(true);
    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
        confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Passwords must match')
    })
    const onSubmit = (values, setErrors) => {
        values.token = token;
        postData(Constants.ApiUrl.auth.resetPassword, values).then((res) => {
            if(res.success){
                confirm({ icon: "success", title: res.message }).then((result) => {
                    if (result.isConfirmed) {
                      navigate('/account/login')
                    }
                  });
            }else{
                setTokenValid(false);
            }
        }).catch(err => {
            setTokenValid(false);
        })
    }
 
    return (
        <>
            {
                isTokenValid ?
                    <div className="content">
                        <div className="top">
                            <div className="text">
                                <h1>Change Password?</h1>
                            </div>
                            <Formik
                                initialValues={{
                                    password: '',
                                    confirmPassword: '',
                                }}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setErrors }) => {
                                    onSubmit(values, setErrors)
                                }}
                            >
                                {() => (
                                    <Form>
                                        <div className="fields">
                                            <div className="form-group">
                                                <Field name="password" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                    width: "2.5rem",
                                                    outline: "none",
                                                    height: "48px",
                                                    border: "none",
                                                    position: "absolute"
                                                }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" type="password" placeholder="Enter New Password" component={TextInput} />
                                            </div>
                                            <div className="form-group">
                                                <Field name="confirmPassword" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                    width: "2.5rem",
                                                    outline: "none",
                                                    height: "48px",
                                                    border: "none",
                                                    position: "absolute"
                                                }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" type="password" placeholder="Confirm New Password" component={TextInput} />
                                            </div>
                                        </div>
                                        <button type='submit' className="btn-theme btn-login">Submit</button>
                                    </Form>)}
                            </Formik>
                        </div>
                        <Link className="text-theme" to="/account/login"><i className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
                    </div>
                    : 
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3 col-12">
                                    <div className="success-inner">
                                        <h1>Oops, this link is expired</h1>
                                        <p>This URL is not valid anymore.</p>
                                        <Link className="text-theme" to="/account/login"><i className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div> 
            }
        </>

    )
}

export default ChangePassword;
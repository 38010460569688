import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, RenderIf, TextInput, } from '../../../../components';
import Constants from '../../../../Constants';
import useAxios from '../../../../hooks/useAxios';
import { getNestedValue, postMedicalRecords, preMedicalRecords } from '../../../../utils/helper';
import { storeInjuryAnalysisEstern, preProcessedMedicalRecordsData, isCaseSaved, formikRefCapture, cancelCaseSubmission } from '../../../../redux/actions/liabilty';
import { Dialog } from '../../../../components';
import DotLoader from "react-spinners/BeatLoader";
import { confirm } from '../../../../utils/swal';
import { useParams } from 'react-router-dom';


const InjuriesAnalysisForm = ({ onSubmit, stepDecrement, innerRef, processPromptInputContentOnServer }) => {
    const { caseId } = useParams();
    const [isOpenProcessedMedicalRecordsModal, setIsOpenProcessedMedicalRecordsModal] = useState(false);
    const [isValidationRequired, setIsValidationRequired] = useState(false)
    const dispatch = useDispatch();
    const { postData } = useAxios()
    const injuryData = useSelector(state => state.injuryData.injury);
    const proccessedMedicalRecordsStored = useSelector(state => state.preProcessMedicalRecords.injuryData);

    useEffect(() => {
        dispatch(formikRefCapture(innerRef))
        console.log(innerRef)
    }, [])

    let [recordArrayLength, setRecordArrayLength] = useState({
        postAccident: 5,
        preAccident: 5
    })

    const postAccidentSchema = {
        providerName: '',
        medicalRecords: [],
        medicalType: '',
        medicalBills: []
    }
    const preAccident = {
        providerName: '',
        medicalRecords: [],
        medicalType: ''
    }

    const initialValues = {
        postAccident: injuryData?.postAccident || Array(5).fill(postAccidentSchema),
        preAccident: injuryData?.preAccident || Array(5).fill(preAccident)
    };

    const addFile = ({ formik, name, e }) => {
        const files = Array.from(e.target.files);

        if (!files?.every(file => file.size > 0)) {
            const dialog = document.createElement('dialog');
            dialog.innerHTML = `
            <div style="color: #18479a;">
                <h2>Warning!</h2>
                <p>Some files uploaded contain 0 bytes.</p>
                <p>Please download the file onto your server or computer, and reupload the file to AI Demand Pro</p>
                <button type='button' style="background-color: #18479a; color: white; padding: 8px 16px; border: none; border-radius: 4px; cursor: pointer;" onclick="this.closest('dialog').remove()">OK</button>
            </div>
        `;
            document.body.appendChild(dialog);
            dialog.showModal();
            e.target.value = '';
            return
        }

        const prevfiles = getNestedValue(formik.values, name, [])
        formik.setFieldValue(name, [...prevfiles, ...files]);
    }

    const deleteFile = ({ formik, name, index }) => {
        const currentFiles = [...getNestedValue(formik.values, name, [])];
        currentFiles.splice(index, 1);
        formik.setFieldValue(name, currentFiles);
    }

    const viewFile = async (file) => {
        if (file instanceof File || file instanceof Blob) {
            const fileUrl = URL.createObjectURL(file);
            window.open(fileUrl, '_blank');
        }
        if (file.s3UrlPath) {
            const response = await postData(Constants.ApiUrl.utils.getSignedUrl, {
                path: file.s3UrlPath
            })
            const signedUrl = response?.data?.signedUrl
            signedUrl && window.open(response?.data?.signedUrl, '_blank');
        }
    }

    const removeRow = ({ record, formik, providerLevelIndex }) => {
        const prevRecords = getNestedValue(formik.values, `${record}`, [])
        prevRecords.splice(providerLevelIndex, 1)
        formik.setFieldValue(record, [...prevRecords]);

        setRecordArrayLength({
            ...recordArrayLength,
            [record]: recordArrayLength[record] - 1
        })

    }

    const addRow = ({ formik, record }) => {
        formik.setFieldValue(record, [...formik.values[record], record === "postAccident" ? postAccidentSchema : preAccident])
    }

    const onFormikSubmitHandler = (values, isDraftCase = false) => {
        if (isDraftCase) {
            const postMedicalRecord = postMedicalRecords(values, caseId);
            const preMedicalRecord = preMedicalRecords(values, caseId);
            if (postMedicalRecord.length || preMedicalRecord.length) {
                dispatch(preProcessedMedicalRecordsData({
                    ...proccessedMedicalRecordsStored,
                    isPreProcessRecordLoading: true,
                }));

            } else {
                confirm({ icon: "error", title: "Please Upload files" });
                return false;
            }
        }
        onSubmit({ injury: values, isDraftCase });
        dispatch(storeInjuryAnalysisEstern(values));
    }

    const getProviderNameandTotalBillAmount = (medicalBillData) => {

        let totalAmount = []
        for (let i = 0; i < medicalBillData?.length; i++) {
            if (i === 0) {
                totalAmount.push({ medicalProviderName: medicalBillData[i].medicalProviderName, totalBillAmount: medicalBillData[i].totalBillAmount })
            } else {
                let updatedAmount = [...totalAmount]
                const isNameExist = totalAmount.findIndex(x => x.medicalProviderName === medicalBillData[i].medicalProviderName)
                if (isNameExist !== -1) {
                    const currentAmount = medicalBillData[i]?.totalBillAmount
                    const previousTotalAmount = updatedAmount[isNameExist]?.amount
                    const totalAmountData = parseFloat(currentAmount) + parseFloat(previousTotalAmount)
                    totalAmount[isNameExist] = { medicalProviderName: medicalBillData[i].medicalProviderName, totalBillAmount: totalAmountData }
                } else {
                    totalAmount.push({ medicalProviderName: medicalBillData[i].medicalProviderName, totalBillAmount: medicalBillData[i].totalBillAmount })
                }
            }
        }

        console.log(totalAmount)
        return totalAmount;
    }

    const onToggleMedicalRecordsCaseDialog = (isDialogOpen) => {

        setIsOpenProcessedMedicalRecordsModal(isDialogOpen)
    }

    const viewMedicalRecords = (e, value) => {
        e.preventDefault()
        setIsOpenProcessedMedicalRecordsModal(value)
    }

    const nextBtnClick = () => {
        setIsValidationRequired(false)
        dispatch(cancelCaseSubmission(true))
        dispatch(isCaseSaved(false))
    }
    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values) => { onFormikSubmitHandler(values) }}
            >
                {(formik) => (
                    <Form>
                        <div className="container" style={{
                            maxWidth: '1400px',
                            margin: '0 auto',
                            padding: '20px'
                        }}>
                            <div className="add-form">
                                <div className="card" style={{
                                    padding: '20px',
                                    backgroundColor: '#fff',
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }}>
                                    {Object.keys(formik?.values || {})?.map((record) => (
                                        <div className='mt-4' key={`${record}_key`}>
                                            {record === 'postAccident' &&
                                                <h2 style={{
                                                    textAlign: 'left',
                                                    marginBottom: '20px',
                                                    color: '#333'
                                                }}>Post-Accident Medical Providers</h2>
                                            }
                                            {record === 'preAccident' &&
                                                <h2 style={{
                                                    textAlign: 'left',
                                                    marginBottom: '20px',
                                                    color: '#333'
                                                }}>Pre-Accident Medical Providers</h2>
                                            }

                                            {Array.isArray(formik.values[record]) && formik.values[record]?.map((_, providerLevelIndex) => {
                                                const medicalRecords = formik?.values?.[record]?.[providerLevelIndex]?.medicalRecords;

                                                return (
                                                    <div className="row" key={`${formik?.values?.[record]?.[providerLevelIndex]}_key`}
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                            margin: '10px 0',
                                                            padding: '10px 0',
                                                            borderBottom: providerLevelIndex < formik.values[record].length - 1 ? '1px solid #eee' : 'none'
                                                        }}>

                                                        {/* Provider Name Column */}
                                                        <div className="col-md-3" style={{
                                                            padding: '0 15px',
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}>
                                                            <div className='mt-2' style={{ width: '100%' }}>
                                                                <Field
                                                                    name={`${record}.${providerLevelIndex}.providerName`}
                                                                    placeholder="Write Medical Provider Name"
                                                                    {...(!providerLevelIndex && { label: "Medical Provider / Facility" })}
                                                                    component={TextInput}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* Upload Section Column */}
                                                        <div className="col-md-6" style={{
                                                            padding: '0 15px',
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}>
                                                            <div className='mt-2'>
                                                                {!providerLevelIndex &&
                                                                    <label style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        marginBottom: '10px'
                                                                    }}>
                                                                        Upload Provider Medical Records
                                                                        <i className="fa-solid fa-upload"></i>
                                                                    </label>
                                                                }

                                                                <div style={{
                                                                    position: 'relative',
                                                                    width: '100%'
                                                                }}>
                                                                    <label htmlFor={`fileInputMedicalRecords_${record}_${providerLevelIndex}`}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            width: '100%',
                                                                            margin: 0
                                                                        }}>
                                                                        <div className="fileInputStyle" style={{
                                                                            backgroundColor: '#F2F2F2',
                                                                            borderRadius: '5px',
                                                                            height: '3rem',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            padding: '0 15px'
                                                                        }}>
                                                                            <i className="fa-solid fa-plus" style={{ marginRight: '10px' }}></i>
                                                                            <span>{medicalRecords?.length ? medicalRecords[0]?.name : 'Upload Medical Record'}</span>
                                                                        </div>
                                                                    </label>

                                                                    <Field
                                                                        type="file"
                                                                        accept="application/pdf"
                                                                        id={`fileInputMedicalRecords_${record}_${providerLevelIndex}`}
                                                                        name={`fileInputMedicalRecords_${record}_${providerLevelIndex}`}
                                                                        style={{ display: 'none' }}
                                                                        multiple
                                                                        onChange={(e) => {
                                                                            addFile({ e, name: `${record}.${providerLevelIndex}.medicalRecords`, formik })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Action Buttons Column */}
                                                        <div className="col-md-3" style={{
                                                            padding: '0 15px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'flex-start',
                                                            marginTop: '8px'
                                                        }}>
                                                            {record === 'postAccident' && (
                                                                <>
                                                                    {providerLevelIndex === 0 ? (
                                                                        <button
                                                                            type='button'
                                                                            className='add-icon'
                                                                            onClick={(e) => addRow({ formik, record })}
                                                                            style={{
                                                                                backgroundColor: '#18479a',
                                                                                color: 'white',
                                                                                border: 'none',
                                                                                borderRadius: '4px',
                                                                                padding: '0px 16px',
                                                                                cursor: 'pointer',
                                                                                marginTop: "28px"
                                                                            }}
                                                                        >
                                                                            <i className="fa-solid fa-plus"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            type='button'
                                                                            className='add-icon'
                                                                            onClick={(e) => removeRow({ record, formik, providerLevelIndex })}
                                                                            style={{
                                                                                backgroundColor: '#18479a',
                                                                                color: 'white',
                                                                                border: 'none',
                                                                                borderRadius: '4px',
                                                                                padding: '0px 16px',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            <i className="fa-solid fa-minus"></i>
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}

                                                            {record === 'preAccident' && (
                                                                <>
                                                                    {providerLevelIndex === 0 ? (
                                                                        <button
                                                                            type='button'
                                                                            className='preMedicalProvider-add-icon'
                                                                            onClick={(e) => addRow({ formik, record })}
                                                                            style={{
                                                                                backgroundColor: '#18479a',
                                                                                color: 'white',
                                                                                border: 'none',
                                                                                borderRadius: '4px',
                                                                                padding: '0px 16px',
                                                                                cursor: 'pointer',
                                                                                marginTop: "28px"
                                                                            }}
                                                                        >
                                                                            <i className="fa-solid fa-plus"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            type='button'
                                                                            className='preMedicalProvider-add-icon'
                                                                            onClick={(e) => removeRow({ record, formik, providerLevelIndex })}
                                                                            style={{
                                                                                backgroundColor: '#18479a',
                                                                                color: 'white',
                                                                                border: 'none',
                                                                                borderRadius: '4px',
                                                                                padding: '0px 16px',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            <i className="fa-solid fa-minus"></i>
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>

                                                        {/* File Display Section */}
                                                        {medicalRecords?.length > 0 && (
                                                            <div className="col-12" style={{
                                                                padding: '15px',
                                                                marginTop: '10px'
                                                            }}>
                                                                {medicalRecords.map((file, index) => (
                                                                    <div key={index} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        backgroundColor: '#F2F2F2',
                                                                        padding: '8px 15px',
                                                                        borderRadius: '4px',
                                                                        marginBottom: '5px'
                                                                    }}>
                                                                        <span style={{ flex: 1 }}>{file.name}</span>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            gap: '10px'
                                                                        }}>
                                                                            <i className='fa-solid fa-eye'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => viewFile(file)}
                                                                            ></i>
                                                                            <i className='fa-solid fa-trash'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => deleteFile({
                                                                                    name: `${record}.${providerLevelIndex}.medicalRecords`,
                                                                                    formik,
                                                                                    index
                                                                                })}
                                                                            ></i>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Bottom Action Buttons */}
                        <div className="btns text-center" style={{
                            padding: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px',
                            alignItems: 'center'
                        }}>
                            <button
                                type='button'
                                className="btn-theme btn-outline me-3"
                                onClick={() => {
                                    dispatch(storeInjuryAnalysisEstern(formik.values));
                                    stepDecrement();
                                }}
                            >
                                Previous
                            </button>
                            <button className="btn-theme" type="submit" onClick={() => nextBtnClick()} ref={innerRef}>Next</button>
                            <button
                                className="btn-theme"
                                style={{
                                    backgroundColor: proccessedMedicalRecordsStored?.isPreProcessRecordLoading ? 'grey' : '#18479a'
                                }}
                                type="button"
                                disabled={proccessedMedicalRecordsStored?.isPreProcessRecordLoading}
                                onClick={(e) => onFormikSubmitHandler(formik.values, true)}
                            >
                                Process Medical Records
                            </button>

                            {proccessedMedicalRecordsStored?.isPreProcessRecordLoading && (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    <DotLoader
                                        color={'#00a6ff'}
                                        loading={proccessedMedicalRecordsStored?.isPreProcessRecordLoading}
                                        size={30}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                    <h3>Please wait, your data is being processed.</h3>
                                </div>
                            )}

                            <RenderIf shouldRender={proccessedMedicalRecordsStored?.medicalProcessedData?.length || proccessedMedicalRecordsStored?.preMedicalProcessedData?.length || proccessedMedicalRecordsStored?.preMedicalBillProccessedData?.length}>
                                <button
                                    type='button'
                                    className="btn-theme"
                                    onClick={(e) => viewMedicalRecords(e, true)}
                                >
                                    View Medical Records
                                </button>
                            </RenderIf>
                        </div>
                    </Form>
                )}
            </Formik>

            {/* Dialog Component */}
            <Dialog
                isModalOpen={isOpenProcessedMedicalRecordsModal}
                onToggleDialog={(isDialogOpen) => onToggleMedicalRecordsCaseDialog(isDialogOpen)}
                mode="full"
                isCloseIconHidden={false}
            >
                <div className='row'>
                    <div className='col-md-4'>
                        <RenderIf shouldRender={proccessedMedicalRecordsStored?.medicalProcessedData?.length}>
                            <h1>Post-Acident Medical Records</h1>
                            <div style={{ height: '37rem', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', color: 'black' }}>
                                {proccessedMedicalRecordsStored?.medicalProcessedData?.map((data, index) => (
                                    <div key={index}>
                                        <RenderIf shouldRender={data.medicalTypeName === "Hospital"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    {values?.treatmentSummary?.map((value, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {value.diagnosis} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "Surgery Center Reports"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Procedure Name:" : ""}</b> {values.
                                                        procedures} </p>
                                                ))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "ER"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Admit Date :" : ""}</b> {values.admittedDate} </p>
                                                    {values?.diagnoses?.map((diagnoses, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {diagnoses} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "MRI Other Imaging"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    {values?.findings?.map((data, i) => (
                                                        <>
                                                            <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "findings :" : ""}</b> {data} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "Consultation Reports"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data?.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Causation :" : ""}</b> {values?.causation} </p>

                                                    {values?.diagnoses?.map((diagnoses, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {diagnoses} </p>

                                                        </>
                                                    ))}

                                                    {values?.treatmentSummary?.map((treatmentSummary, index) => (
                                                        <>
                                                            {
                                                                treatmentSummary?.PlanDiscussion?.map((PlanDiscussionData, index) => (
                                                                    <>
                                                                        <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Plan Discussion  :" : ""}</b> {PlanDiscussionData} </p>

                                                                    </>
                                                                ))
                                                            }

                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        {data && data.medicalTypeName === "All Other Medical Records" && (
                                            <RenderIf shouldRender={true}>
                                                <p style={{ marginTop: '2rem', fontSize: '25px' }}>
                                                    <b>Medical Provider Name: </b> {data.name}
                                                </p>
                                                <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                                <p style={{ marginTop: '1rem', fontSize: '20px' }}>
                                                    <b>Objective:</b> {data?.objectFindings}
                                                </p>
                                                {data?.diagnosis?.map((diagnosesValue, index) => (
                                                    <>
                                                        {diagnosesValue?.values?.map((diagnosesValues, i) => (
                                                            <React.Fragment key={`${index}-${i}`}>
                                                                <p style={{ fontSize: '20px' }}>
                                                                    <b>{i === 0 ? "Diagnoses:" : ""}</b> {diagnosesValues}
                                                                </p>
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                ))}
                                            </RenderIf>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </RenderIf>

                    </div>
                    <div className='col-md-4'>
                        <RenderIf shouldRender={proccessedMedicalRecordsStored?.preMedicalProcessedData?.length}>
                            <h1>Pre-Acident Medical Records</h1>
                            <div style={{ height: '37rem', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', color: 'black' }}>
                                {proccessedMedicalRecordsStored?.preMedicalProcessedData?.map((data, index) => (
                                    <div key={index}>
                                        <RenderIf shouldRender={data.medicalTypeName === "Hospital"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    {values?.treatmentSummary?.map((value, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {value.diagnosis} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "Surgery Center Reports"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Procedure Name:" : ""}</b> {values.
                                                        procedures} </p>
                                                ))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "ER"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Admit Date :" : ""}</b> {values.admittedDate} </p>
                                                    {values?.diagnoses?.map((diagnoses, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {diagnoses} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "MRI Other Imaging"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    {values?.findings?.map((data, i) => (
                                                        <>
                                                            <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "findings :" : ""}</b> {data} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "Consultation Reports"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data?.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Causation :" : ""}</b> {values?.causation} </p>

                                                    {values?.diagnoses?.map((diagnoses, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {diagnoses} </p>

                                                        </>
                                                    ))}

                                                    {values?.treatmentSummary?.map((treatmentSummary, index) => (
                                                        <>
                                                            {
                                                                treatmentSummary?.PlanDiscussion?.map((PlanDiscussionData, index) => (
                                                                    <>
                                                                        <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Plan Discussion  :" : ""}</b> {PlanDiscussionData} </p>

                                                                    </>
                                                                ))
                                                            }

                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        {data && data.medicalTypeName === "All Other Medical Records" && (
                                            <RenderIf shouldRender={true}>
                                                <p style={{ marginTop: '2rem', fontSize: '25px' }}>
                                                    <b>Medical Provider Name: </b> {data.name}
                                                </p>
                                                <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                                <p style={{ marginTop: '1rem', fontSize: '20px' }}>
                                                    <b>Objective:</b> {data?.objectFindings}
                                                </p>
                                                {data?.diagnosis?.map((diagnosesValue, index) => (
                                                    <>
                                                        {diagnosesValue?.values?.map((diagnosesValues, i) => (
                                                            <React.Fragment key={`${index}-${i}`}>
                                                                <p style={{ fontSize: '20px' }}>
                                                                    <b>{i === 0 ? "Diagnoses:" : ""}</b> {diagnosesValues}
                                                                </p>
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                ))}
                                            </RenderIf>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </RenderIf>
                    </div>
                    <div className='col-md-4'>
                        <RenderIf shouldRender={proccessedMedicalRecordsStored?.preMedicalBillProccessedData?.length}>
                            <h1>Medical Bill Records</h1>
                            <div style={{ height: '37rem', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', color: 'black' }}>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#f2f2f2' }}>
                                            <th style={{ padding: '10px', border: '1px solid #ccc' }}>S.No</th>
                                            <th style={{ padding: '10px', border: '1px solid #ccc' }}>Medical Provider Name</th>
                                            <th style={{ padding: '10px', border: '1px solid #ccc' }}>Total Bill Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {proccessedMedicalRecordsStored?.preMedicalBillProccessedData?.map((data, index) => (
                                            <tr key={index}>
                                                <td style={{ padding: '10px', border: '1px solid #ccc', textAlign: 'center' }}>
                                                    {index + 1}
                                                </td>
                                                <td style={{ padding: '10px', border: '1px solid #ccc' }}>
                                                    {data.medicalProviderName}
                                                </td>
                                                <td style={{ padding: '10px', border: '1px solid #ccc', textAlign: 'right' }}>
                                                    {data.totalBillAmount}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </RenderIf>

                    </div>
                </div>
            </Dialog >
        </>
    );
}




export default InjuriesAnalysisForm;